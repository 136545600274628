import React from "react";
import PropTypes from "prop-types";
import config from "../../../content/meta/config";

import FacebookIcon from "!svg-react-loader!../../images/svg-icons/facebook.svg?name=facebook";
import LineIcon from "!svg-react-loader!../../images/svg-icons/line.svg?name=line";
import { Map } from "../Map";

const SocialLinks = () => (
  <>
    <a href={config.contact.facebook.url} target="_blank" rel="noopener noreferrer">
      <FacebookIcon />
      <span className="label">{config.contact.facebook.name}</span>
    </a>
    <a href={config.contact.line.url} target="_blank" rel="noopener noreferrer">
      <LineIcon />
      <span className="label">{config.contact.line.name}</span>
    </a>
  </>
);

const Contact = props => {
  const { theme } = props;

  return (
    <React.Fragment>
      <div className="contact-wrapper">
        <div className="container">
          <div className="contact">
            <div className="mobile-number">
              <a href={config.contact.mobile.href}>{config.contact.mobile.label}</a>
            </div>
            <div className="company">
              <div>{config.companyName.th}</div>
              <div>{config.contact.address.th}</div>
            </div>
            <div className="social">
              <SocialLinks />
            </div>
          </div>
          <div className="time">
            <div className="time-title">Opening Hours</div>
            {config.contact.time.map(item => (
              <React.Fragment key={JSON.stringify(item)}>
                <div className="time-label">{item.label.th}</div>
                <div className="time-value">{item.value.th}</div>
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="map">
          <Map theme={theme} />
        </div>
      </div>

      {/* --- STYLES --- */}
      <style jsx>{`
        .contact-wrapper {
          background: ${theme.color.brand.primaryBackground};
          font-family: ${theme.font.family.thaiText};
          font-size: ${theme.font.size.xs};
          color: ${theme.color.neutral.white};
        }

        .container {
          display: block;
        }

        .mobile-number {
          font-size: ${theme.font.size.m};
          padding: 2rem 0 1rem;
          text-align: center;
          font-weight: 500;

          a {
            color: white;
            text-decoration: none;

            &:hover {
              opacirt: 0.5;
            }
          }
        }

        .company {
          text-align: center;
          width: 70%;
          margin: 0 auto;
          line-height: 1.6em;
        }

        .time {
          padding: 0 0 2rem;
          line-height: 1.6em;
          text-align: center;

          .time-title {
            font-size: ${theme.font.size.m};
            padding-bottom: 1rem;
          }
        }

        .time-label {
          font-weight: bold;
        }

        .social {
          padding: 2rem;
          margin: 0 auto;
          text-align: center;

          & > :global(a) {
            margin: 0 10px;
            color: ${theme.color.neutral.white};

            :global(.label) {
              display: none;
            }
          }

          :global(svg) {
            fill: #ffffff;
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        .map {
          width: 100%;
          height: 400px;
        }

        @from-width desktop {
          .contact-wrapper {
          }

          .container {
            display: flex;
            height: 300px;
          }

          .contact {
            width: 70%;
            display: flex;
            flex-direction: column;
            justify-content: center;
          }

          .mobile-number {
            padding: 0 0 2rem 0;
          }

          .social {
            padding: 2rem 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;

            & > :global(a) {
              display: flex;
              align-items: center;
            }

            & > :global(a .label) {
              display: block;
              padding-left: 0.5rem;
            }

            :global(svg) {
              width: 2rem;
              height: 2rem;
            }
          }

          .time {
            width: 30%;
            padding: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: left;
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Contact.propTypes = {
  theme: PropTypes.object.isRequired
};

export default Contact;
